import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import { shieldIcon } from '@workday/canvas-accent-icons-web';
import Example from './examples/AccentIcon.tsx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const ExampleCodeBlock = makeShortcode("ExampleCodeBlock");
const SymbolDoc = makeShortcode("SymbolDoc");
const InternalContent = makeShortcode("InternalContent");
const SearchableIconList = makeShortcode("SearchableIconList");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Guidance" mdxType="TabPanel">
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <p>{`Accent icons are intended to be used as simplified illustrative elements to add visual interest
without distracting your audience from their intended task. Accent icons are not intended to be
actionable, or to be used as entry points between pages.`}</p>
      <SideBySide mdxType="SideBySide">
        <Suggestion guidance="Use Accent Icons for illustrative purposes." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "664px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/a2ee53b23cba13832377f75c37afee22/31493/accent-do.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "15.675675675675677%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image demonstrating the use of Accent Icons for illustrative purposes.",
                  "title": "Image demonstrating the use of Accent Icons for illustrative purposes.",
                  "src": "/static/a2ee53b23cba13832377f75c37afee22/31493/accent-do.png",
                  "srcSet": ["/static/a2ee53b23cba13832377f75c37afee22/1efb2/accent-do.png 370w", "/static/a2ee53b23cba13832377f75c37afee22/31493/accent-do.png 664w"],
                  "sizes": "(max-width: 664px) 100vw, 664px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <SideBySide mdxType="SideBySide">
        <Suggestion status="error" guidance="Use Accent Icons in place of System Icons." mdxType="Suggestion">
          <p><span parentName="p" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "465px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/e2c2040a9ea05b843b358086ba596462/9ff85/accent-dont.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "48.37837837837837%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "Image demonstrating the use of Accent Icons in place of System Icons.",
                  "title": "Image demonstrating the use of Accent Icons in place of System Icons.",
                  "src": "/static/e2c2040a9ea05b843b358086ba596462/9ff85/accent-dont.png",
                  "srcSet": ["/static/e2c2040a9ea05b843b358086ba596462/1efb2/accent-dont.png 370w", "/static/e2c2040a9ea05b843b358086ba596462/9ff85/accent-dont.png 465w"],
                  "sizes": "(max-width: 465px) 100vw, 465px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></p>
        </Suggestion>
      </SideBySide>
      <h2 {...{
        "id": "color"
      }}>{`Color`}</h2>
      <p>{`Accent icons are Blueberry 400 by default, though this can be overridden to other Canvas colors for
branding/theming as long as these meet a minimum contrast ratio of (3.1) or as otherwise stated by
WCAG guidelines for informational graphic contrast standards.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/90a35755b5ba3e6253818f2889486d9f/07a9c/accent_icon_color.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of Accent Icon color contrast.",
              "title": "Example of Accent Icon color contrast.",
              "src": "/static/90a35755b5ba3e6253818f2889486d9f/50383/accent_icon_color.png",
              "srcSet": ["/static/90a35755b5ba3e6253818f2889486d9f/1efb2/accent_icon_color.png 370w", "/static/90a35755b5ba3e6253818f2889486d9f/50383/accent_icon_color.png 740w", "/static/90a35755b5ba3e6253818f2889486d9f/07a9c/accent_icon_color.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "background-color"
      }}>{`Background Color`}</h3>
      <p>{`Accent icons should be recolored to pass color contrast standards when the icon is presented on a
colored or dark background.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fcb24571c4d4afd437bf28cc9874f68c/07a9c/accent_icon_color_background.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "48.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of Accent Icon color options.",
              "title": "Example of Accent Icon color options.",
              "src": "/static/fcb24571c4d4afd437bf28cc9874f68c/50383/accent_icon_color_background.png",
              "srcSet": ["/static/fcb24571c4d4afd437bf28cc9874f68c/1efb2/accent_icon_color_background.png 370w", "/static/fcb24571c4d4afd437bf28cc9874f68c/50383/accent_icon_color_background.png 740w", "/static/fcb24571c4d4afd437bf28cc9874f68c/07a9c/accent_icon_color_background.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "alignment"
      }}>{`Alignment`}</h2>
      <p>{`Accent icons should be center aligned vertically or horizontally depending on the situation, this
ensures that the icons look visually balanced when in close proximity to another asset. When using
text next to an icon, icons should be center aligned to the text.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0fa8b9ae236c416c8378f1a628f3767a/07a9c/accent_icon_alignment.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "45.13513513513514%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of Accent Icon alignment.",
              "title": "Example of Accent Icon alignment.",
              "src": "/static/0fa8b9ae236c416c8378f1a628f3767a/50383/accent_icon_alignment.png",
              "srcSet": ["/static/0fa8b9ae236c416c8378f1a628f3767a/1efb2/accent_icon_alignment.png 370w", "/static/0fa8b9ae236c416c8378f1a628f3767a/50383/accent_icon_alignment.png 740w", "/static/0fa8b9ae236c416c8378f1a628f3767a/07a9c/accent_icon_alignment.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "sizing"
      }}>{`Sizing`}</h2>
      <p>{`Accent icons are made to a specific standard, changing the size of an icon can cause images to blur,
Canvas does not recommend scaling icons. If for a specific reason you do need to scale an icon we
recommend following Canvas spacing rules resizing in increments of 8 pixels. Do not scale Accent
Icons below 40px.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/219d7fdfd95819c1bfc7ae4ba541e8ce/07a9c/accent_icon_scale.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "25.135135135135133%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Example of Accent Icon scaling.",
              "title": "Example of Accent Icon scaling.",
              "src": "/static/219d7fdfd95819c1bfc7ae4ba541e8ce/50383/accent_icon_scale.png",
              "srcSet": ["/static/219d7fdfd95819c1bfc7ae4ba541e8ce/1efb2/accent_icon_scale.png 370w", "/static/219d7fdfd95819c1bfc7ae4ba541e8ce/50383/accent_icon_scale.png 740w", "/static/219d7fdfd95819c1bfc7ae4ba541e8ce/07a9c/accent_icon_scale.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "web-examples"
      }}>{`Web Examples`}</h2>
      <p>{`The icons shipped in `}<inlineCode parentName="p">{`@workday/canvas-accent-icons-web`}</inlineCode>{` are just SVGs with some additional metadata.
If you'd like to easily use them in React, use the `}<inlineCode parentName="p">{`AccentIcon`}</inlineCode>{` component from
`}<inlineCode parentName="p">{`@workday/canvas-kit-react/icon`}</inlineCode>{`:`}</p>
      <ExampleCodeBlock code={Example} scope={{
        shieldIcon
      }} showCode={true} mdxType="ExampleCodeBlock" />
      <SymbolDoc name="AccentIcon" hideHeading headingStart={2} mdxType="SymbolDoc" />
      <InternalContent mdxType="InternalContent">
        <h2 {...{
          "id": "ios-examples"
        }}>{`iOS Examples`}</h2>
        <p>{`Coming soon!`}</p>
        <h2 {...{
          "id": "android-examples"
        }}>{`Android Examples`}</h2>
        <p>{`Coming soon!`}</p>
      </InternalContent>
    </TabPanel>
    <TabPanel data-id="Gallery" mdxType="TabPanel">
      <SearchableIconList iconType="wd-accent-icons" mdxType="SearchableIconList" />
    </TabPanel>
    <TabPanel data-id="Create Your Own" mdxType="TabPanel">
      <h2 {...{
        "id": "create-your-own"
      }}>{`Create Your Own`}</h2>
      <p>{`Can’t find the icon you need in our library? Why not make your own! Follow the steps outlined below
to create your own icon to contribute back to the Canvas Design System. Before creating a new icon,
make sure you have checked over the `}<a parentName="p" {...{
          "href": "#tab=gallery"
        }}>{`Gallery`}</a>{` and searched for keywords to surface an
icon that may already exist.`}</p>
      <InternalContent mdxType="InternalContent">
        <p><a parentName="p" {...{
            "href": "https://www.figma.com/file/PTAPKG5kbIBujrAsnObeuO/Accent-Icon-Contribution-Workspace"
          }}>{`Follow this link`}</a>{`
to access the guided Accent Icon Workspace file in Figma.`}</p>
      </InternalContent>
      <h2 {...{
        "id": "base-grid"
      }}>{`Base Grid`}</h2>
      <p>{`Accent icons are built on a base grid, using a grid will help you draw your icon to Workdays brand
specific parameters, keeping things consistent. Canvas maintains a base grid of 56x56px for Accent
icons.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/57304ce6c4d32905f6199469d8fa5586/07a9c/accent_icon_base_grid.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Base grid illustration for Aystem Icons.",
              "title": "Base grid illustration for Aystem Icons.",
              "src": "/static/57304ce6c4d32905f6199469d8fa5586/50383/accent_icon_base_grid.png",
              "srcSet": ["/static/57304ce6c4d32905f6199469d8fa5586/1efb2/accent_icon_base_grid.png 370w", "/static/57304ce6c4d32905f6199469d8fa5586/50383/accent_icon_base_grid.png 740w", "/static/57304ce6c4d32905f6199469d8fa5586/07a9c/accent_icon_base_grid.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "safe-area"
      }}>{`Safe Area`}</h3>
      <p>{`The safe area ensures that the Accent icon remains fully visible should the icon be scaled up or
down. No part of the icon should extend past the surrounding white space of the safe area.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/4b0ef5d6e6d8a2e3c20d0069862e08cb/07a9c/accent_icon_safe_area.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Safe Area illustration for Accent Icons.",
              "title": "Safe Area illustration for Accent Icons.",
              "src": "/static/4b0ef5d6e6d8a2e3c20d0069862e08cb/50383/accent_icon_safe_area.png",
              "srcSet": ["/static/4b0ef5d6e6d8a2e3c20d0069862e08cb/1efb2/accent_icon_safe_area.png 370w", "/static/4b0ef5d6e6d8a2e3c20d0069862e08cb/50383/accent_icon_safe_area.png 740w", "/static/4b0ef5d6e6d8a2e3c20d0069862e08cb/07a9c/accent_icon_safe_area.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "key-shapes"
      }}>{`Key Shapes`}</h3>
      <p>{`Using a key shape provides a consistent size for basic shapes and proportions across the entire
Accent icon suite. They help maintain a common visual style.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6d972570526c3b482c23d8467b48e75c/07a9c/accent_icon_key_shapes.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Key Shapes illustration for Accent Icons.",
              "title": "Key Shapes illustration for Accent Icons.",
              "src": "/static/6d972570526c3b482c23d8467b48e75c/50383/accent_icon_key_shapes.png",
              "srcSet": ["/static/6d972570526c3b482c23d8467b48e75c/1efb2/accent_icon_key_shapes.png 370w", "/static/6d972570526c3b482c23d8467b48e75c/50383/accent_icon_key_shapes.png 740w", "/static/6d972570526c3b482c23d8467b48e75c/07a9c/accent_icon_key_shapes.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><strong parentName="p">{`All Key Shapes`}</strong></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/fd2717e477be1b6c58d0fe622d29981b/ab3e6/accent_icon_all_key_shapes.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "26.216216216216214%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "All Key Shapes using Accent Icons to demonstate consistancy.",
              "title": "All Key Shapes using Accent Icons to demonstate consistancy.",
              "src": "/static/fd2717e477be1b6c58d0fe622d29981b/50383/accent_icon_all_key_shapes.png",
              "srcSet": ["/static/fd2717e477be1b6c58d0fe622d29981b/1efb2/accent_icon_all_key_shapes.png 370w", "/static/fd2717e477be1b6c58d0fe622d29981b/50383/accent_icon_all_key_shapes.png 740w", "/static/fd2717e477be1b6c58d0fe622d29981b/ab3e6/accent_icon_all_key_shapes.png 1441w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h3 {...{
        "id": "secondary-element"
      }}>{`Secondary Element`}</h3>
      <p>{`This area allows you to add additional emphasis or information such as a plus or minus in a
consistent manner. Utilize this element only when your Accent Icon requires it for differentiation
from others.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/7517fbc8c01bf04b7ed89ed879af6ce9/07a9c/accent_icon_secondary-element.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Depiction of the secondary element area to add additional emphasis.",
              "title": "Depiction of the secondary element area to add additional emphasis.",
              "src": "/static/7517fbc8c01bf04b7ed89ed879af6ce9/50383/accent_icon_secondary-element.png",
              "srcSet": ["/static/7517fbc8c01bf04b7ed89ed879af6ce9/1efb2/accent_icon_secondary-element.png 370w", "/static/7517fbc8c01bf04b7ed89ed879af6ce9/50383/accent_icon_secondary-element.png 740w", "/static/7517fbc8c01bf04b7ed89ed879af6ce9/07a9c/accent_icon_secondary-element.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "strokes"
      }}>{`Strokes`}</h2>
      <p>{`Maintain a 2px inner stroke on all shapes used to build an icon, this includes the use of circles.
An inner stroke allows the icon to remain consistent when a corner radius is applied later on.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/48d2aecce24d011beee9850a68c549db/07a9c/accent_icon_strokes.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "2px stroke illustration for Accent Icons.",
              "title": "2px stroke illustration for Accent Icons.",
              "src": "/static/48d2aecce24d011beee9850a68c549db/50383/accent_icon_strokes.png",
              "srcSet": ["/static/48d2aecce24d011beee9850a68c549db/1efb2/accent_icon_strokes.png 370w", "/static/48d2aecce24d011beee9850a68c549db/50383/accent_icon_strokes.png 740w", "/static/48d2aecce24d011beee9850a68c549db/07a9c/accent_icon_strokes.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "corners"
      }}>{`Corners`}</h2>
      <p>{`A corner radius of 2px should be applied to a shape, maintaining a consistent corner radius on all
shapes to ensure visual consistency across the entire Accent icon suite.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/7b1ae92979dc4b805661838e7461da02/07a9c/accent_icon_corners.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Corner radius illustration for Accent Icons.",
              "title": "Corner radius illustration for Accent Icons.",
              "src": "/static/7b1ae92979dc4b805661838e7461da02/50383/accent_icon_corners.png",
              "srcSet": ["/static/7b1ae92979dc4b805661838e7461da02/1efb2/accent_icon_corners.png 370w", "/static/7b1ae92979dc4b805661838e7461da02/50383/accent_icon_corners.png 740w", "/static/7b1ae92979dc4b805661838e7461da02/07a9c/accent_icon_corners.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "angles"
      }}>{`Angles`}</h2>
      <p>{`Use 45° angles for even anti-aliasing on icons, maintain 15° steps when more extreme angles are
required.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e9f948e7abd40adc14eba93faca6a08f/07a9c/accent_icon_angles.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Angles of degrees illustration for Accent Icons.",
              "title": "Angles of degrees illustration for Accent Icons.",
              "src": "/static/e9f948e7abd40adc14eba93faca6a08f/50383/accent_icon_angles.png",
              "srcSet": ["/static/e9f948e7abd40adc14eba93faca6a08f/1efb2/accent_icon_angles.png 370w", "/static/e9f948e7abd40adc14eba93faca6a08f/50383/accent_icon_angles.png 740w", "/static/e9f948e7abd40adc14eba93faca6a08f/07a9c/accent_icon_angles.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "density"
      }}>{`Density`}</h2>
      <p>{`Canvas advise a minimum of 2px between each shape to ensure the icons can be accurately depicted on
screen, anything less may diminish the icons appearance.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/eb86f36ddec9a684eba4d04db1669aa1/07a9c/accent_icon_density.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Density of pixels illustration for Accent Icons.",
              "title": "Density of pixels illustration for Accent Icons.",
              "src": "/static/eb86f36ddec9a684eba4d04db1669aa1/50383/accent_icon_density.png",
              "srcSet": ["/static/eb86f36ddec9a684eba4d04db1669aa1/1efb2/accent_icon_density.png 370w", "/static/eb86f36ddec9a684eba4d04db1669aa1/50383/accent_icon_density.png 740w", "/static/eb86f36ddec9a684eba4d04db1669aa1/07a9c/accent_icon_density.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "layers"
      }}>{`Layers`}</h2>
      <p>{`There are 2 layer options available for Accent Icons, Outline layers are set to Blueberry 400 by
default, Fill layers are set to white. These layers can be targeted in code to customize the colors.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f1bc0cb244727d7e4aef00f0323fdd37/07a9c/accent_icon_layers.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Layer usage illustration for Accent Icons.",
              "title": "Layer usage illustration for Accent Icons.",
              "src": "/static/f1bc0cb244727d7e4aef00f0323fdd37/50383/accent_icon_layers.png",
              "srcSet": ["/static/f1bc0cb244727d7e4aef00f0323fdd37/1efb2/accent_icon_layers.png 370w", "/static/f1bc0cb244727d7e4aef00f0323fdd37/50383/accent_icon_layers.png 740w", "/static/f1bc0cb244727d7e4aef00f0323fdd37/07a9c/accent_icon_layers.png 1440w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <h2 {...{
        "id": "naming"
      }}>{`Naming`}</h2>
      <p>{`Generic names make icons more discoverable and scalable, catering for more use cases. When naming an
icon try to pick a generic name that describes the object itself, rather than something specific to
your product or use case.`}</p>
      <InternalContent mdxType="InternalContent">
        <h2 {...{
          "id": "contribution"
        }}>{`Contribution`}</h2>
        <p>{`Once you're ready to submit your icon, go check out the information supplied in the Contributing
Back To Canvas section of this site to help you make your submission.`}</p>
        <p>{`Thank you for making a contribution!`}</p>
      </InternalContent>
    </TabPanel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
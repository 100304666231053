
  import React from 'react';
import {colors} from '@workday/canvas-kit-react/tokens';
import {AccentIcon} from '@workday/canvas-kit-react/icon';
import {shieldIcon} from '@workday/canvas-accent-icons-web';

const Example = () => (
  <>
    <AccentIcon icon={shieldIcon} />
    <AccentIcon icon={shieldIcon} color={colors.pomegranate500} />
    <AccentIcon icon={shieldIcon} size={80} />
  </>
);

export default Example;
  Example.__RAW__ = "import React from 'react';\nimport {colors} from '@workday/canvas-kit-react/tokens';\nimport {AccentIcon} from '@workday/canvas-kit-react/icon';\nimport {shieldIcon} from '@workday/canvas-accent-icons-web';\n\nexport default () => (\n  <>\n    <AccentIcon icon={shieldIcon} />\n    <AccentIcon icon={shieldIcon} color={colors.pomegranate500} />\n    <AccentIcon icon={shieldIcon} size={80} />\n  </>\n);\n";